import React from 'react';
import { HashLink } from 'react-router-hash-link';

const NavLinks = () => {
    return (
        <>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-[#09746e]" smooth to="/#clinica">
                A Clínica    
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-[#09746e]" to="/#intro">
                 Agendamento
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-[#09746e]" smooth to="/#corpoclinico">
                 Corpo Clínico
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-[#09746e]" smooth to="/#convenios">
                Convênios
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-[#09746e]" smooth to="/#localizacao">
                Localização
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-[#09746e]" smooth to="/#contato">
                Contato
            </HashLink>
        </>
    )
}

export default NavLinks;
