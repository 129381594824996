import React, { useEffect } from 'react';
//import AOS from 'aos';
//import "aos/dist/aos.css";
import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
// All pages
import Home from './pages/Home';
//import Contact from './pages/Contact';
//import DemoProduct from './pages/DemoProduct';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import {useDocTitle} from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';

function App() {
  useEffect(() => {
    const aos_init = () => {
    }

    window.addEventListener('load', () => {
      aos_init();
    });
  }, []);

  useDocTitle("ConectarClinicasIntegradas");

  return (
    <>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
          <div className="App">
            <FloatingWhatsApp
               phoneNumber='5561996898342' // Required
               accountName='Conectar Clinicas Integradas' // Optional
               //avatar='/public/avatar.webp' // Optional
               chatMessage='Oi, como podemos ajudar?'
               statusMessage='Disponível' // Optional
               placeholder='Digite aqui... ' // Optional
               allowEsc={true} // Optional
            />
          </div>
        </ScrollToTop>
      </Router>
    </>
  );
}


export default App;
