import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

import "leaflet/dist/leaflet.css";
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import {Icon} from 'leaflet'

const Cta = () => {

    const position = [-15.839248295839012, -48.023920282318045];

    return (


        <div className="mt-8 bg-gray-100" id="localizacao">
            <section data-aos="fade-up">
                <div className="my-4 py-4 mb-4">
                    <h2 className="my-2 text-center text-3xl text-[#09746e] uppercase font-bold">Localização</h2>
                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-blue-900'></div>
                    </div>
                </div>
                <div className="flex grid sm:grid-cols-3 grid-rows-1 grid-flow-col gap-1 mb-6 justify-center mb-4" id="mapa">
                    <div className="row-span-1 mb-4">
                    </div>
                    <div className="row-span-10 mb-4">
                        <MapContainer center={position} zoom={15} style={{ width: '300px', height: '300px', margin: 'auto',
                                                                            position: 'relative' }}>
                            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                            <Marker position={position} icon={new Icon({iconUrl: markerIconPng, 
                                                                iconSize: [27, 49], 
                                                                iconAnchor: [12, 41]
                                                                })}>
                                <Popup>
                                    Conectar Clínicas Integradas.
                                </Popup>
                            </Marker>
                        </MapContainer>
                    </div>
                </div>
                <div className='row-span-1 mb-4'>
                    <br></br>
                </div>
            </section>
        </div>

    );
};

export default Cta;