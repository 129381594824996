import React from 'react';
import NavBar from '../components/Navbar/NavBar';
import heroImg from '../images/web-dev.png';

const Hero = () => {
    return (
        <>
            <div className="hero" id='clinica'>
                <div>
                    <NavBar />
                </div>
                
                <div className="m-auto overflow-hidden mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-5/6" data-aos="zoom-in">

                    <div id='hero' className="flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left">
                        <div className="lg:w-1/2 flex flex-col justify-center" data-aos="zoom-in" data-aos-delay="200">
                            <h1 className="mb-5 md:text-5xl text-3xl font-bold text-[#09746e]">
                            {/* We build digital solutions to help businesses scale */}
                                A Conectar Clínicas Integradas é inovadora!
                            </h1>
                            <div className="text-xl font-semibold tracking-tight mb-5 text-gray-500">Com ambiente aconchegante, acolhedor e com um espaço físico adequado para fornecer atendimento de alta qualidade.</div>
                        </div>
                        <div className="flex lg:justify-end w-full lg:w-1/4" data-aos="fade-up" data-aos-delay="600">
                            <img alt="card img" className="rounded-t float-right duration-1000 w-full" src={heroImg} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Hero;