import React from 'react';
import helaine from '../images/equipe/Helaine.png'; 
import rayane from '../images/equipe/Rayane.png';
import viviane from '../images/equipe/Viviane.png';
import thais from '../images/equipe/Thais.png';

import potira from '../images/equipe/potira.png'; 
import thainara from '../images/equipe/thainara.png';
import thatiane from '../images/equipe/thatiane.png';
import renata from '../images/equipe/renata.png';

import sofa from '../images/icons/sofa.png'
import online from '../images/icons/online.png'

const Services = () => {

    return (
        <div id="corpoclinico" className="bg-gray-100 py-12" >
            <section data-aos="zoom-in-down">
                    <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-3xl text-[#09746e] uppercase font-bold">Corpo Clínico</h2>
                        
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-blue-900'></div>
                        </div>
                        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-[#09746e]">Todos com capacitação e treinamento para atendimento de excelência para nossos pacientes.</h2>
                    </div>

                    
                    <div className="px-12 mb-6" data-aos="fade-down" data-aos-delay="600">
                        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
                            
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={helaine} />
                                    <h2 className="font-semibold my-4 text-2xl text-center text-[#09746e] ">Helaine Albuquerque</h2>
                                    <h2 className="font-semibold my-4 text-2xl text-center">Psicóloga, neuropsicóloga e psicanalista</h2>
                                    <p className="text-md font-medium">
                                     Graduada pelo Centro Universitário Euro Americano - Unieuro, Psicanalista pela Escola de Psicanálise de Brasília, com pós-graduação em saúde mental e apaixonada por auxiliar pessoas em sua jornada de autoconhecimento e desenvolvimento pessoal. Especialista em saúde mental e neuropsicologia.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={rayane} />
                                    <h2 className="font-semibold my-4 text-2xl text-center text-[#09746e] ">Rayane Lisboa</h2>
                                    <h2 className="font-semibold my-4 text-2xl text-center">Neuropsicóloga</h2>
                                    <p className="text-md font-medium">
                                    Sou neuropsicóloga e da Gestalt terapia, atendimento de adolescente, adulto e idoso. Uma grande experiência em avaliação neurológicas.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                    <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={viviane} />
                                    <h2 className="font-semibold my-4 text-2xl text-center text-[#09746e] ">Viviane Guedes</h2>
                                    <h2 className="font-semibold my-4 text-2xl text-center ">Psicóloga Clínica</h2>
                                    <p className="text-md font-medium">
                                        Pós graduada em Terapia Cognitiva Comportamental (TCC). Graduanda em Avaliação Psicológica e Orientação Profissional.                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={thais} />
                                    <h2 className="font-semibold my-4 text-2xl text-center text-[#09746e] ">Thaís Martins</h2>
                                    <h2 className="font-semibold my-4 text-2xl text-center ">Psicóloga</h2>
                                    <p className="text-md font-medium">
                                        ...
                                    </p>
                                </div>
                            </div>                    
                        </div>
                    </div>
                    <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
                            
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={renata} />
                                    <h2 className="font-semibold my-4 text-2xl text-center text-[#09746e] ">Renata Mendonça</h2>
                                    <h2 className="font-semibold my-4 text-2xl text-center">Psicopedagoga</h2>
                                    <p className="text-md font-medium">
                                    Pedagoga, especialista em Psicopedagogia, casada e mãe de um lindo rapaz. Atuante há mais de 20 anos em salas de aulas de escolas da rede privada do DF e há mais de 4 anos em atendimentos psicopedagógicos clínicos ou domiciliares. Procuro levar meu amor e vivências aos atendimentos e às famílias dos aprendentes.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={thainara} />
                                    <h2 className="font-semibold my-4 text-2xl text-center text-[#09746e] ">Thaynara Lemos</h2>
                                    <h2 className="font-semibold my-4 text-2xl text-center">Fonoaudióloga</h2>
                                    <p className="text-md font-medium">
                                    Terapia de linguagem infantil e adulto. Terapia de fala (transtorno motor de fala). Terapia de motricidade orofacial. Intervenção com laser terapia. Intervenção PROMPT
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                    <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={thatiane} />
                                    <h2 className="font-semibold my-4 text-2xl text-center text-[#09746e] ">Thatyane Oliveira</h2>
                                    <h2 className="font-semibold my-4 text-2xl text-center ">Fisioterapeuta</h2>
                                    <p className="text-md font-medium">
                                    Atendimento Pediátrico e Neonatal. Atuação em distúrbios respiratórios gerais, doenças raras, reabilitação em Doenças Neuromusculares e Laserterapia. Pós Graduada em Fisioterapia Respiratória, Cardiovascular e Oncologia.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={potira} />
                                    <h2 className="font-semibold my-4 text-2xl text-center text-[#09746e] ">Potira Lima</h2>
                                    <h2 className="font-semibold my-4 text-2xl text-center ">Nutricionista</h2>
                                    <p className="text-md font-medium">
                                        Formada em nutrição e pós graduada em nutrição comportamental. Com orientação educacional, planos qualitativos e quantitativos tem o trabalho com foco em emagrecimento, melhora de performance em treinos e de composição corporal. O objetivo é fazer você ter liberdade com o alimento, sem terrorismo nutricional, respeitando a sua individualidade.
                                    </p>
                                </div>
                            </div>                    
                        </div>
                    </div>
            </section>

            <section>
                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="zoom-out">
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <div className='text-[#09746e] mb-4'>
                            <img alt="sofa" width="100" height="100" src={sofa} />
                            </div>
                            <h3 className="text-3xl mb-6 text-[#09746e] font-bold">Atendimento <span className='font-black'>Presencial</span></h3>
                            <div>
                            </div>
                        </div>
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <div className='text-[#09746e] mb-4'>
                                <img alt="online" width="90" height="90" src={online} />
                            </div>
                            <h3 className="text-3xl mb-6 text-[#09746e] font-bold">Atendimento <span className='font-black'>Online</span></h3>
                        </div>
                    </div>
                    <p className='my-3 text-xl text-gray-600 mb-6 font-semibold'>
                    O atendimento presencial oferece uma avaliação direta e detalhada, enquanto o atendimento online proporciona conveniência e acessibilidade, ambos desempenhando papéis complementares no cuidado integral do paciente
                    </p>
                </div>
            </section>
        </div>
    )
}

export default Services;