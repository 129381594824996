import React from 'react';

import afeb from '../images/convenios/afeb.png';
import allianz from '../images/convenios/allianz.png';
import bacen from '../images/convenios/bacen.png';
import bndes from '../images/convenios/bndes.png';
import caesan from '../images/convenios/caesan.png';

import capesesp from '../images/convenios/capesesp.png';
import casembrapa from '../images/convenios/casembrapa.png';
import codevasf from '../images/convenios/codevasf.jpeg';
import conab from '../images/convenios/conab.png';
import evida from '../images/convenios/evida.png';

import embratel from '../images/convenios/embratel.png';
import fascal from '../images/convenios/fascal.png';
import fusex from '../images/convenios/fusex.png';
import inas from '../images/convenios/inas.png';
import notredame from '../images/convenios/notredame.png';

import omint from '../images/convenios/omint.png';
import pf from '../images/convenios/pf.png';
import planassiste from '../images/convenios/planassiste.png';
import postal from '../images/convenios/postal.png';
import proasa from '../images/convenios/proasa.png';

import real from '../images/convenios/real.png';
import samp from '../images/convenios/samp.png';
import caixa from '../images/convenios/caixa.png';
import serpro from '../images/convenios/serpro.png';
import sis from '../images/convenios/sis.png';

import stfmed from '../images/convenios/stfmed.png';
import plasjmustm from '../images/convenios/plasjmustm.png';
import prosaudetjdft from '../images/convenios/prosaudetjdft.png';
import tresaude from '../images/convenios/tresaude.webp';
import prosocial from '../images/convenios/prosocial.png';


import trt5saude from '../images/convenios/trt5saude.png';
import tst from '../images/convenios/tst.png';
import unafisco from '../images/convenios/unafisco.png';

const clientImage1 = {
    height: '6.5rem',
    width: '10.2rem',
    mixBlendMode: 'colorBurn'
}

const Clients = () => {
    return (
        <div className="mt-8 bg-gray-100" id="convenios">
            <section data-aos="fade-up">
                <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-3xl text-[#09746e] uppercase font-bold">Convênios</h2>
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-blue-900'></div>
                        </div>
                        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-[#09746e]">Relação de convênios aceitos.</h2>
                </div>

                <div className="p-16 justify-center" data-aos="fade-in" data-aos-delay="600">
                    <div className="grid sm:grid-cols-5 lg:grid-cols-5 justify-center ">
                        <div style={clientImage1} className= "overflow-hidden mb-9 flex justify-center transition-all ease-in-out opacity-90 hover:opacity-100">
                            <img src={afeb}  alt="convenios" />                           
                        </div>
                        <div style={clientImage1} className="overflow-hidden mb-9 flex justify-center transition-all ease-in-out opacity-90 hover:opacity-100">
                            <img src={allianz} alt="convenios" />                            
                        </div> 
                        <div style={clientImage1} className="overflow-hidden mb-9 flex justify-center transition-all ease-in-out opacity-90 hover:opacity-100">
                            <img src={bacen}  alt="convenios" />                            
                        </div>
                        <div style={clientImage1} className="overflow-hidden mb-9 flex justify-center transition-all ease-in-out opacity-90 hover:opacity-100">
                            <img src={bndes}  alt="convenios" />                            
                        </div>
                        <div style={clientImage1} className="overflow-hidden mb-9 flex justify-center transition-all ease-in-out opacity-90 hover:opacity-100">
                            <img src={caesan}  alt="convenios" />                            
                        </div>
                    </div>

                    <div className="grid sm:grid-cols-5 lg:grid-cols-5 justify-center">
                        <div style={clientImage1} className= "overflow-hidden mb-9 flex justify-center transition-all ease-in-out opacity-90 hover:opacity-100">
                            <img src={capesesp}  alt="convenios" />                           
                        </div>
                        <div style={clientImage1} className="overflow-hidden mb-9 flex justify-center transition-all ease-in-out opacity-90 hover:opacity-100">
                            <img src={casembrapa} alt="convenios" />                            
                        </div> 
                        <div style={clientImage1} className="overflow-hidden mb-9 lex justify-center transition-all ease-in-out opacity-90 hover:opacity-100">
                            <img src={codevasf}  alt="convenios" />                            
                        </div>
                        <div style={clientImage1} className="overflow-hidden mb-9 flex justify-center transition-all ease-in-out opacity-90 hover:opacity-100">
                            <img src={conab}  alt="convenios" />                            
                        </div>
                        <div style={clientImage1} className="overflow-hidden mb-9 flex justify-center transition-all ease-in-out opacity-90 hover:opacity-100">
                            <img src={evida}  alt="convenios" />                            
                        </div>

                    </div>

                    <div className="grid sm:grid-cols-5 lg:grid-cols-5 justify-center">
                        <div style={clientImage1} className= "overflow-hidden mb-9 flex justify-center transition-all ease-in-out opacity-90 hover:opacity-100">
                            <img src={embratel}  alt="convenios" />                           
                        </div>
                        <div style={clientImage1} className="overflow-hidden mb-9 flex justify-center transition-all ease-in-out opacity-90 hover:opacity-100">
                            <img src={fascal} alt="convenios" />                            
                        </div> 
                        <div style={clientImage1} className="overflow-hidden mb-9 lex justify-center transition-all ease-in-out opacity-90 hover:opacity-100">
                            <img src={fusex}  alt="convenios" />                            
                        </div>
                        <div style={clientImage1} className="overflow-hidden mb-9 flex justify-center transition-all ease-in-out opacity-90 hover:opacity-100">
                            <img src={inas}  alt="convenios" />                            
                        </div>
                        <div style={clientImage1} className="overflow-hidden mb-9 flex justify-center transition-all ease-in-out opacity-90 hover:opacity-100">
                            <img src={notredame}  alt="convenios" />                            
                        </div>

                    </div>

                    <div className="grid sm:grid-cols-5 lg:grid-cols-5 justify-center">
                        <div style={clientImage1} className= "overflow-hidden mb-9 flex justify-center transition-all ease-in-out opacity-90 hover:opacity-100">
                            <img src={omint}  alt="convenios" />                           
                        </div>
                        <div style={clientImage1} className="overflow-hidden mb-9 flex justify-center transition-all ease-in-out opacity-90 hover:opacity-100">
                            <img src={pf} alt="convenios" />                            
                        </div> 
                        <div style={clientImage1} className="overflow-hidden mb-9 lex justify-center transition-all ease-in-out opacity-90 hover:opacity-100">
                            <img src={planassiste}  alt="convenios" />                            
                        </div>
                        <div style={clientImage1} className="overflow-hidden mb-9 flex justify-center transition-all ease-in-out opacity-90 hover:opacity-100">
                            <img src={postal}  alt="convenios" />                            
                        </div>
                        <div style={clientImage1} className="overflow-hidden mb-9 flex justify-center transition-all ease-in-out opacity-90 hover:opacity-100">
                            <img src={proasa}  alt="convenios" />                            
                        </div>

                    </div>

                    <div className="grid sm:grid-cols-5 lg:grid-cols-5 justify-center">
                        <div style={clientImage1} className= "overflow-hidden mb-9 flex justify-center transition-all ease-in-out opacity-90 hover:opacity-100">
                            <img src={real}  alt="convenios" />                           
                        </div>
                        <div style={clientImage1} className="overflow-hidden mb-9 flex justify-center transition-all ease-in-out opacity-90 hover:opacity-100">
                            <img src={samp} alt="convenios" />                            
                        </div> 
                        <div style={clientImage1} className="overflow-hidden mb-9 lex justify-center transition-all ease-in-out opacity-90 hover:opacity-100">
                            <img src={caixa}  alt="convenios" />                            
                        </div>
                        <div style={clientImage1} className="overflow-hidden mb-9 flex justify-center transition-all ease-in-out opacity-90 hover:opacity-100">
                            <img src={serpro}  alt="convenios" />                            
                        </div>
                        <div style={clientImage1} className="overflow-hidden mb-9 flex justify-center transition-all ease-in-out opacity-90 hover:opacity-100">
                            <img src={sis}  alt="convenios" />                            
                        </div>

                    </div>

                    <div className="grid sm:grid-cols-5 lg:grid-cols-5 justify-center">
                        <div style={clientImage1} className= "overflow-hidden mb-9 flex justify-center transition-all ease-in-out opacity-90 hover:opacity-100">
                            <img src={stfmed}  alt="convenios" />                           
                        </div>
                        <div style={clientImage1} className="overflow-hidden mb-9 flex justify-center transition-all ease-in-out opacity-90 hover:opacity-100">
                            <img src={plasjmustm} alt="convenios" />                            
                        </div> 
                        <div style={clientImage1} className="overflow-hidden mb-9 lex justify-center transition-all ease-in-out opacity-90 hover:opacity-100">
                            <img src={prosaudetjdft}  alt="convenios" />                            
                        </div>
                        <div style={clientImage1} className="overflow-hidden mb-9 flex justify-center transition-all ease-in-out opacity-90 hover:opacity-100">
                            <img src={tresaude}  alt="convenios" />                            
                        </div>
                        <div style={clientImage1} className="overflow-hidden mb-9 flex justify-center transition-all ease-in-out opacity-90 hover:opacity-100">
                            <img src={prosocial}  alt="convenios" />                            
                        </div>

                    </div>

                    <div className="grid sm:grid-cols-5 lg:grid-cols-5 justify-center">
                        <div style={clientImage1} className= "overflow-hidden mb-9 flex justify-center transition-all ease-in-out opacity-90 hover:opacity-100">
                            <img src={trt5saude}  alt="convenios" />                           
                        </div>
                        <div style={clientImage1} className="overflow-hidden mb-9 flex justify-center transition-all ease-in-out opacity-90 hover:opacity-100">
                            <img src={tst} alt="convenios" />                            
                        </div> 
                        <div style={clientImage1} className="overflow-hidden mb-9 lex justify-center transition-all ease-in-out opacity-90 hover:opacity-100">
                            <img src={unafisco}  alt="convenios" />                            
                        </div>

                    </div>

                </div>
            </section>
        </div>
    )
}

export default Clients;